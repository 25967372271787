import {RiArticleLine} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField, defineType} from 'sanity-typed-schema';

import {
	BULLET,
	CALL_TO_ACTION,
	EMPHASIS,
	H2,
	H3,
	H4,
	H5,
	H6,
	IMAGE,
	LINK,
	NUMBERED,
	portableText,
	STRONG,
	UNDERLINE,
} from '@/sanity/components/portable-text/schema';
import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';
import {meta} from '@/sanity/schemas/primitives/meta';
import {ARTICLES_PAGE_PATH} from '@/shared/constants';

export const article = () =>
	defineType({
		name: 'article',
		type: 'document',
		title: 'Artikkel',
		icon: RiArticleLine,
		fields: [title(), slug(), heading(), lead(), image(), content(), meta()],
		preview: {
			select: {
				title: 'title',
				slug: 'slug',
			},
			prepare: (select) => ({
				title: select.title,
				subtitle: `${ARTICLES_PAGE_PATH}/` + (select.slug?.current ?? '...'),
			}),
		},
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel',
		description:
			'Tittelen vises i nettleserens vidustittel, menyer og listevisninger og bør være kort og konsis. Merk at tittelen er forskjellig fra overskriften, som redigeres lenger ned.',
		validation: (Rule: Rule) => Rule.required(),
	});

const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		description: 'En kort introduduksjon til denne artikkelen.',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
	});

const slug = () =>
	defineField({
		name: 'slug',
		type: 'slug',
		title: 'Slug',
		description:
			'Siste del av URLen til denne artikkelen på nettstedet. Slug bør formatteres med små bokstaver og uten spesialtegn. Det enkleste er å generere denne basert på artikkelens tittel.',
		options: {
			source: 'title',
		},
		validation: (Rule: Rule) =>
			Rule.custom((param?: {current: string}) => {
				if (!param) {
					return 'Obligatorisk';
				}
				if (param.current?.includes(' ') || param.current?.includes('/')) {
					return 'Slug kan ikke inneholde mellomrom eller skråstreker';
				}
				return true;
			}),
	});

const lead = () =>
	defineField({
		...portableText({
			decorators: [STRONG, EMPHASIS],
			annotations: [],
			styles: [],
			lists: [],
			customTypes: [],
		}),
		name: 'lead',
		title: 'Ingress',
		description:
			'En kort tekst som oppsummerer hovedinnholdet i artikkelen på et avsnitt eller to.',
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		description:
			'Et bilde som representerer innholdet i artikkelen. Brukes gjerne sammen med ingressen i listevisninger.',
	});

const content = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			styles: [H2, H3, H4, H5, H6],
			lists: [BULLET, NUMBERED],
			decorators: [STRONG, EMPHASIS, UNDERLINE],
			customTypes: [CALL_TO_ACTION, IMAGE],
		}),
		name: 'content',
		title: 'Brødtekst',
		description: 'En lengre tekst som utgjør hovedinnholdet i denne artikkelen.',
	});

export type ArticleSchema = Output<typeof article>;
