import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

type LayoutProps = {
	spacingTop?: {
		initialValue?: 'none' | 'small' | 'normal' | 'large';
	};
	spacingBottom?: {
		initialValue?: 'none' | 'small' | 'normal' | 'large';
	};
};

export const layout = (props?: LayoutProps) => {
	return defineField({
		name: 'layout',
		title: 'Layout',
		type: 'object',
		group: 'layout',
		fields: [
			defineField({
				name: 'spacingTop',
				title: 'Kantstørrelse, topp',
				description:
					'Ønsket avstand fra øvre kant av innholdet opp til foregående innholdsblokk.',
				type: 'string',
				initialValue: props?.spacingTop?.initialValue ?? 'normal',
				options: {
					list: [
						{title: 'Ingen', value: 'none'},
						{title: 'Liten', value: 'small'},
						{title: 'Normal', value: 'normal'},
						{title: 'Stor', value: 'large'},
					] as const,
					layout: 'radio',
				},
				validation: (Rule: Rule) => Rule.required(),
			}),
			defineField({
				name: 'spacingBottom',
				title: 'Kantstørrelse, bunn',
				description:
					'Ønsket avstand fra nedre kant av innholdet ned til etterfølgende innholdsblokk.',
				type: 'string',
				initialValue: props?.spacingBottom?.initialValue ?? 'normal',
				options: {
					list: [
						{title: 'Ingen', value: 'none'},
						{title: 'Liten', value: 'small'},
						{title: 'Normal', value: 'normal'},
						{title: 'Stor', value: 'large'},
					] as const,
					layout: 'radio',
				},
				validation: (Rule: Rule) => Rule.required(),
			}),
		],
	});
};

export type LayoutSchema = Output<typeof layout>;
