import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

export const ALT_TEXT = defineField({
	name: 'altText',
	title: 'Alternativ tekst',
	description:
		'Bildebeskrivelse for blinde og svaksynte. Kun nødvendig om bildet er viktig for forståelsen av innholdet. Om bildet kun er et visuelt element, la feltet være tomt.',
	type: 'string',
});
export const CAPTION = defineField({
	name: 'caption',
	title: 'Bildetekst',
	type: 'string',
	description: 'En synling bildetekst som supplerer bildet med mer informasjon.',
});
export const CREDIT = defineField({
	name: 'credit',
	title: 'Opphavsrett',
	type: 'string',
	description: 'Navn på personen eller organisasjonen som eier opphavsretten til bildet.',
});
export const WIDTH = defineField({
	name: 'width',
	title: 'Bredde',
	type: 'number',
	description:
		'Ønsket bredde bildet skal vises i. Merk at faktisk bredde kan variere avhengig av skjermstørrelse og andre faktorer.',
});

type Field = typeof ALT_TEXT | typeof WIDTH | typeof CAPTION | typeof CREDIT;

export const imageWeb = <const F extends readonly Field[]>(args: {fields: F}) => {
	return defineField({
		name: 'image',
		title: 'Bilde',
		type: 'image',
		options: {
			hotspot: true,
		},
		fields: args.fields as unknown as Field[],
		preview: {
			select: {
				media: 'asset',
				altText: 'altText',
				caption: 'caption',
				credit: 'credit',
			},
			prepare: (select) => {
				const title = select.altText || select.caption || select.credit || 'Bilde';
				return {
					title: title,
					media: select.media,
				};
			},
		},
	});
};

const imageWebAll = () => imageWeb({fields: [ALT_TEXT, CREDIT, CAPTION]});

export type ImageWebSchema = Output<typeof imageWebAll>;
