import {RiImageFill} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';

import {
	CALL_TO_ACTION,
	EMPHASIS,
	LINK,
	portableText,
	STRIKE_THROUGH,
	STRONG,
} from '../../portable-text/schema';

export const heroWithSideImage = () =>
	defineField({
		name: 'heroWithSideImage',
		title: 'Hero med sidestilt bilde',
		icon: RiImageFill,
		type: 'object',
		fields: [heading(), introduction(), image()],
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
		],
		preview: {
			select: {
				title: 'heading',
				image: 'image',
			},
			prepare: (select) => ({
				title: select.title ?? 'Mangler overskrift...',
				subtitle: 'Hero med sidestilt bilde',
				media: select.image.asset ? select.image : RiImageFill,
			}),
		},
	});

export const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		description: 'Overskriften skal kort introdusere innholdet på siden.',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

export const introduction = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			decorators: [STRONG, EMPHASIS, STRIKE_THROUGH],
			customTypes: [CALL_TO_ACTION],
			styles: [],
			lists: [],
		}),
		name: 'introduction',
		title: 'Ingress',
		description: 'Ingressen skal kort introdusere innholdet på siden.',
		group: 'content',
	});

export const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		name: 'image',
		title: 'Bilde',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

export type HeroWithSideImageSchema = Output<typeof heroWithSideImage> & {
	_type: 'heroWithSideImage';
};
