import {RiTeamLine, RiUser3Line} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField, defineType} from 'sanity-typed-schema';

import {ALT_TEXT, imageWeb} from '@/sanity/schemas/primitives/image-web';

export const person = () =>
	defineType({
		name: 'person',
		type: 'document',
		title: 'Person',
		icon: RiUser3Line,
		fields: [name(), role(), image(), phone(), email()],
		preview: {
			select: {
				name: 'name',
				role: 'role',
				image: 'image',
			},
			prepare: (select) => {
				const image = select.image?.asset ? select.image : RiUser3Line;
				return {
					title: select.name ?? 'Mangler navn...',
					subtitle: select.role,
					media: image,
				};
			},
		},
	});

const name = () =>
	defineField({
		name: 'name',
		type: 'string',
		title: 'Navn',
		description: 'Fullt navn på personen.',
		validation: (Rule: Rule) => Rule.required(),
	});

const phone = () =>
	defineField({
		name: 'phone',
		type: 'string',
		title: 'Telefonnummer',
		description: 'Telefonnummer til personen.',
	});

const email = () =>
	defineField({
		name: 'email',
		type: 'string',
		title: 'E-postadresse',
		description: 'E-postadresse til personen.',
	});

const role = () =>
	defineField({
		name: 'role',
		type: 'string',
		title: 'Rolle',
		description: 'Rollen til personen i organisasjonen.',
	});

const image = () =>
	defineField({
		...imageWeb({fields: [ALT_TEXT]}),
		description: 'Et bilde som representerer personen.',
	});

export type PersonSchema = Output<typeof person>;

export const personGroup = () =>
	defineType({
		name: 'personGroup',
		type: 'document',
		title: 'Persongruppering',
		icon: RiTeamLine,
		fields: [title(), persons()],
		preview: {
			select: {
				title: 'title',
				persons: 'persons',
			},
			prepare: (select) => {
				const subtitle = `Gruppering med ${select.persons?.length ?? 0} personer`;

				return {
					title: select.title,
					subtitle: subtitle,
				};
			},
		},
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel',
		validation: (Rule: Rule) => Rule.required(),
	});

const persons = () =>
	defineField({
		name: 'persons',
		type: 'array',
		title: 'Personer',
		description: 'Liste med personer som tilhører grupperingen i sortert rekkefølge.',
		of: [{title: 'Referanse til person', type: 'reference', to: [{type: 'person'}]}],
	});

export type PersonGroupSchema = Output<typeof personGroup>;
