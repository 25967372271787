import {GoVideo} from 'react-icons/go';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

export const video = () =>
	defineField({
		name: 'video',
		type: 'object',
		title: 'Video',
		icon: GoVideo,
		fields: [title(), id()],
		preview: {
			select: {
				title: 'title',
			},
			prepare: (select) => {
				const title = select.title || 'Uten tittel';
				return {
					title: title,
					subtitle: 'Video',
				};
			},
		},
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel for skjermlesere',
		description:
			'Denne er ikke direkte synlig, men brukes for å representere videoen for brukere med skjermleser. Tittelen bør være kort og beskrivende, og den bør oppsummere innholdet eller formålet med videoen.',
		validation: (Rule: Rule) => Rule.required(),
	});

const id = () =>
	defineField({
		name: 'id',
		type: 'string',
		title: 'Video ID',
		description: 'ID for video på Vimeo. Eksempel: 328181683',
		validation: (Rule: Rule) => Rule.required(),
	});

export type VideoSchema = Output<typeof video>;
