import {RiImageFill} from 'react-icons/ri';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {imageWeb} from '@/sanity/schemas/primitives/image-web';

import {
	CALL_TO_ACTION,
	EMPHASIS,
	LINK,
	portableText,
	STRIKE_THROUGH,
	STRONG,
} from '../../portable-text/schema';

export const heroWithBgImage = () =>
	defineField({
		name: 'heroWithBgImage',
		title: 'Plakat',
		icon: RiImageFill,
		type: 'object',
		fields: [heading(), introduction(), image()],
		preview: {
			select: {
				title: 'heading',
				image: 'backgroundImage',
			},
			prepare: ({title, image}) => {
				return {
					title: title ?? 'Mangler overskrift...',
					subtitle: 'Plakat',
					media: image?.asset ? image : RiImageFill,
				};
			},
		},
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
		],
	});

export const heading = () =>
	defineField({
		name: 'heading',
		title: 'Overskrift',
		description: 'Overskriften skal kort introdusere innholdet på siden.',
		type: 'string',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

export const introduction = () =>
	defineField({
		...portableText({
			annotations: [LINK],
			decorators: [STRONG, EMPHASIS, STRIKE_THROUGH],
			customTypes: [CALL_TO_ACTION],
			styles: [],
			lists: [],
		}),
		name: 'introduction',
		title: 'Ingress',
		description: 'Ingressen skal kort introdusere innholdet på siden.',
		group: 'content',
	});

const image = () =>
	defineField({
		...imageWeb({fields: []}),
		name: 'backgroundImage',
		title: 'Bakgrunnsbilde',
		description: 'Bildet som skal ligge i bakgrunnen av plakaten.',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

export type HeroWithBgImageSchema = Output<typeof heroWithBgImage> & {_type: 'heroWithBgImage'};
