import {defineField} from 'sanity-typed-schema';

import {imageWeb} from './image-web';

export const meta = () =>
	defineField({
		name: 'meta',
		type: 'object',
		title: 'Metadata',
		fields: [title(), metaDescription(), metaImage()],
	});

const metaDescription = () =>
	defineField({
		name: 'description',
		type: 'string',
		title: 'Beskrivelse',
		description:
			'Kan brukes til å oppsummere innholdet for søkemotorer og andre tjenester. Kan vises i søkeresultatene i disse.',
	});

const title = () =>
	defineField({
		name: 'title',
		type: 'string',
		title: 'Tittel',
		description:
			'Tittelen på innholdet. Kan vises i søkeresultatene i søkemotorer og andre tjenester.',
	});

const metaImage = () =>
	defineField({
		...imageWeb({fields: []}),
		title: 'Bilde',
		description:
			'Kan brukes av tjenester som Facebook eller Twitter for å forhåndsvise lenker til innhold på nettstedet. Er normalt ikke direkte synlig på nettstedet.',
	});
