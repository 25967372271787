import {RxButton} from 'react-icons/rx';
import type {Rule} from 'sanity';
import type {Output} from 'sanity-typed-schema';
import {defineField} from 'sanity-typed-schema';

import {link, validateLink} from '@/sanity/schemas/primitives/link';

// CTA

export const callToAction = () =>
	defineField({
		title: 'Knapp',
		name: 'callToAction',
		type: 'object',
		fields: [text(), ...linkFields(), color()],
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
		],
		preview: {
			select: {
				title: 'text',
				type: 'type',
			},
			prepare: (select) => ({
				title: select.title ?? 'Uten tekst',
				subtitle: 'Knapp',
			}),
		},
		validation: (Rule: Rule) => Rule.custom(validateLink),
	});

const text = () =>
	defineField({
		name: 'text',
		type: 'string',
		title: 'Tekst',
		description: 'Teksten som vises på knappen.',
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

const linkFields = () =>
	link().fields.map((field) => {
		return {
			...field,
			group: 'content',
		};
	});

const color = () =>
	defineField({
		title: 'Farge',
		name: 'color',
		type: 'string',
		initialValue: 'primary',
		options: {
			list: [
				{title: 'Primær', value: 'primary'},
				{title: 'Sekundær', value: 'secondary'},
			],
			layout: 'radio',
		},
		group: 'style',
	});

export type CallToActionColorSchema = Output<typeof color>;

// CTA line

export const callToActionLine = () =>
	defineField({
		title: 'Knappelinje',
		name: 'callToActionLine',
		type: 'object',
		fields: [callToActions(), size()],
		icon: RxButton,
		groups: [
			{
				name: 'content',
				title: 'Innhold',
				default: true,
			},
			{
				name: 'style',
				title: 'Utseende',
			},
			{
				name: 'layout',
				title: 'Layout',
			},
		],
		preview: {
			select: {
				type: 'callToActions',
			},
			prepare: (select) => {
				const buttons = select?.type?.length;

				if (!buttons) {
					return {
						title: 'Mangler knapper',
						subtitle: 'Knappelinje',
					};
				}

				if (buttons === 1) {
					return {
						title: '1 knapp',
						subtitle: 'Knappelinje',
					};
				}

				return {
					title: `${buttons} knapper`,
					subtitle: 'Knappelinje',
				};
			},
		},
	});

const callToActions = () =>
	defineField({
		name: 'callToActions',
		type: 'array',
		title: 'Knapper',
		description: 'Knappene som skal vises i knappelinjen.',
		of: [callToAction()],
		validation: (Rule: Rule) => Rule.required(),
		group: 'content',
	});

const size = () =>
	defineField({
		title: 'Størrelse',
		description: 'Ønsket størrelse på knappene i linjen.',
		name: 'size',
		type: 'string',
		initialValue: 'medium',
		options: {
			list: [
				{title: 'Liten', value: 'small'},
				{title: 'Medium', value: 'medium'},
				{title: 'Stor', value: 'large'},
			],
			layout: 'radio',
		},
		group: 'style',
	});

export type CallToActionSizeSchema = Output<typeof size>;

export type CallToActionLineSchema = Output<typeof callToActionLine>;
